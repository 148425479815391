import { Grid } from "@kiwicom/orbit-components";
import ButtonWebImage from "../../../../src/images/component-structure/web/button.svg";
import ButtonPrimaryActionDo from "../../../../src/images/button-primary-action-do.svg";
import ButtonPrimaryActionDont from "../../../../src/images/button-primary-action-dont.svg";
import ButtonShowHierarchyDo from "../../../../src/images/button-show-hierarchy-do.svg";
import ButtonShowHierarchyDont from "../../../../src/images/button-show-hierarchy-dont.svg";
import ButtonSameImportanceDo from "../../../../src/images/button-same-importance-do.svg";
import ButtonSameImportanceDont from "../../../../src/images/button-same-importance-dont.svg";
import ButtonManipulationDo from "../../../../src/images/button-manipulation-do.svg";
import ButtonManipulationDont from "../../../../src/images/button-manipulation-dont.svg";
import * as React from 'react';
export default {
  Grid,
  ButtonWebImage,
  ButtonPrimaryActionDo,
  ButtonPrimaryActionDont,
  ButtonShowHierarchyDo,
  ButtonShowHierarchyDont,
  ButtonSameImportanceDo,
  ButtonSameImportanceDont,
  ButtonManipulationDo,
  ButtonManipulationDont,
  React
};